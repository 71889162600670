<template >
    <div>
       <Sales/>
    </div>
</template>
<script>
export default {
    components:{
        Sales: () => import('@/components/transfer/Sales')
    }
}
</script>
<style >
    
</style>